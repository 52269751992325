<template>
  <div class="border bg-white-blue rounded border-info border-left-4 mb-3 no-break"
    v-bind:class="{ 'rule-not-applicable': isNotApplicable, 'future-rule': isFuture }">
    <div class="p-2 border-bottom">
      <div class="d-flex align-items-center">
        <h5 class="mb-0 text-gray-600 font-weight-bold">
          <!-- <pre>{{ JSON.stringify(rule) }}</pre> -->
          {{ rule.modality }}

          <span v-if="rule.description" v-b-tooltip.hover v-bind:title="rule.description">
            <BIcon icon="info-circle" class="no-print ml-3" />
          </span>

          <span>
            <BIcon v-if="isNotApplicable" icon="x-circle" class="text-danger ml-3" />
            <!-- <BIcon v-else-if="isFuture" icon="arrow-right-circle-fill" class="text-warning ml-3" /> -->
            <BIcon v-else-if="!isFuture" icon="check-circle" class="text-success ml-3" />
          </span>

          <!-- <span class="ml-3 font-weight-bold text-success small" v-if="isFuture && ageToRetire">Possível aposentadoria em {{ageToRetire}} </span> -->

          <h6 v-if="rule.description" class="show-only-print mb-0 mt-2">
            <BIcon icon="info-circle" />
            {{ rule.description }}
          </h6>
        </h5>
        <h6 class="mb-0 text-gray-500 font-weight-bold small ml-auto">({{ rule.ruleLaw || rule.ruleYear }})</h6>
      </div>

      <div v-if="rule.observation">
        <h6 class="mb-0 text-gray-500 font-weight-bold small ml-auto">({{ rule.observation }})</h6>
      </div>
    </div>

    <div class="d-flex align-items-center">
      <h5 class="mb-0 text-gray-600 font-weight-bold">
        <span>
          <BIcon v-if="isFuture && whenToRetire" icon="arrow-right-circle-fill" class="text-warning ml-3" />
        </span>

        <span class="ml-3 font-weight-bold text-success small" v-if="retiredDate">Possível aposentadoria em {{
          retiredDate }}</span>
        <span class="ml-3 font-weight-bold text-success small" v-if="isFuture && whenToRetire">Possível aposentadoria em
          {{ whenToRetire.format('DD/MM/YYYY') }} </span>
      </h5>
    </div>

    <div>
      <div>
        <div class="row align-items-center">
          <div class="col-md-2">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Gênero</h6>
              <!-- <TextInfo v-bind:text="gender" /> -->
              <h6 class="mb-0 font-weight-bold text-info">{{ rule.gender }}</h6>
            </div>
          </div>

          <div class="col-md-3">
            <div class="p-3" v-if="getDecreasedAge() || rule.necessaryAge">
              <h6 class="text-uppercase text-gray-600 small">Idade necessária</h6>
              <div class="d-flex align-items-center">
                <template v-if="getDecreasedAge()">
                  <h6 class="mb-0 font-weight-bold text-gray mr-2">
                    <strike> {{ rule.necessaryAge }} anos</strike>
                  </h6>
                  <h6 class="mb-0 font-weight-bold text-info">{{ getDecreasedAge() }} anos</h6>
                </template>
                <template v-else>
                  <h6 class="mb-0 font-weight-bold text-info">{{ rule.necessaryAge }} anos</h6>
                </template>
                <h6 class="mb-0 font-weight-bold text-success small ml-2">({{ formattedCurrentAge }}) </h6>
              </div>
              <h6 class="mb-0 font-weight-bold text-success small mt-2" v-if="ageToRetire">Completará em
                {{ ageToRetire.format('DD/MM/YYYY') }} </h6>
            </div>
            <div class="p-3" v-else>
              <h6 class="text-uppercase text-gray-600 small">Idade necessária</h6>
              <div class="d-flex align-items-center">
                <h6 class="mb-0 font-weight-bold text-gray-400">Não aplicável</h6>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Contribuição necessária</h6>
              <div class="d-flex align-items-center">
                <h6 class="mb-0 font-weight-bold text-info" v-if="rule.necessaryContribution">
                  {{ rule.necessaryContribution }} anos
                  {{ increasedContribution }}</h6>
                <h6 class="mb-0 font-weight-bold text-gray-400" v-else>Não aplicável</h6>
              </div>
              <!-- <h6 class="mb-0 font-weight-bold text-success small mt-2" v-if="rule.increasedNecessaryContribution">Com pedágio: {{rule.increasedNecessaryContribution}} anos</h6> -->
              <h6 class="mb-0 font-weight-bold text-success small mt-2" v-if="rule.necessaryContribution">
                ({{ contributionTime }})
              </h6>
              <h6 class="mb-0 font-weight-bold text-success small mt-2"
                v-if="rule.necessaryContribution && contributionToRetire">
                Completará em {{ contributionToRetire.format('DD/MM/YYYY') }} </h6>
            </div>
          </div>

          <div class="col-md-2">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Tempo de Cargo</h6>
              <div class="d-flex align-items-center">
                <h6 class="mb-0 font-weight-bold text-info" v-if="rule.necessaryPositionTime">
                  {{ rule.necessaryPositionTime }} anos
                </h6>
                <h6 class="mb-0 font-weight-bold text-gray-400" v-else>Não aplicável</h6>
              </div>
              <h6 class="mb-0 font-weight-bold text-success small mt-2" v-if="rule.necessaryPositionTime">
                ({{ positionTime }})</h6>
              <h6 class="mb-0 font-weight-bold text-success small mt-2"
                v-if="rule.necessaryPositionTime && positionToRetire">
                Completará em {{ positionToRetire.format('DD/MM/YYYY') }} </h6>
            </div>
          </div>

          <div class="col-md-2">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Serviço Público</h6>
              <div class="d-flex align-items-center">
                <h6 class="mb-0 font-weight-bold text-info" v-if="rule.necessaryPublicServiceTime">
                  {{ rule.necessaryPublicServiceTime }} anos</h6>
                <h6 class="mb-0 font-weight-bold text-gray-400" v-else>Não aplicável</h6>
              </div>
              <h6 class="mb-0 font-weight-bold text-success small mt-2" v-if="rule.necessaryPublicServiceTime">
                ({{ publicServiceTime }})</h6>
              <h6 class="mb-0 font-weight-bold text-success small mt-2"
                v-if="rule.necessaryPublicServiceTime && publicServiceToRetire">Completará em {{
                  publicServiceToRetire.format('DD/MM/YYYY') }} </h6>
            </div>
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col-md-2">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Magistério</h6>
              <div class="d-flex align-items-center">
                <h6 class="mb-0 font-weight-bold text-info" v-if="rule.necessaryTeachingTime">
                  {{ rule.necessaryTeachingTime }}
                  anos {{ increasedTeachingTime }}</h6>
                <h6 class="mb-0 font-weight-bold text-gray-400" v-else>Não aplicável</h6>
              </div>
              <h6 class="mb-0 font-weight-bold text-success small mt-2" v-if="rule.necessaryTeachingTime">
                ({{ teachingTime }})
              </h6>
              <h6 class="mb-0 font-weight-bold text-success small mt-2"
                v-if="rule.necessaryTeachingTime && teachingToRetire">
                Completará em {{ teachingToRetire.format('DD/MM/YYYY') }} </h6>
            </div>
          </div>

          <div class="col-md-3">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Tempo de Carreira</h6>
              <div class="d-flex align-items-center">
                <h6 class="mb-0 font-weight-bold text-info" v-if="rule.necessaryCarreerTime">
                  {{ rule.necessaryCarreerTime }} anos
                </h6>
                <h6 class="mb-0 font-weight-bold text-gray-400" v-else>Não aplicável</h6>
              </div>
              <h6 class="mb-0 font-weight-bold text-success small mt-2" v-if="rule.necessaryCarreerTime">
                ({{ carreerTime }})</h6>
              <h6 class="mb-0 font-weight-bold text-success small mt-2"
                v-if="rule.necessaryCarreerTime && carreerToRetire">
                Completará em {{ carreerToRetire.format('DD/MM/YYYY') }} </h6>
            </div>
          </div>

          <div class="col-md-3">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Pontos</h6>
              <div class="d-flex align-items-center">
                <h6 class="mb-0 font-weight-bold text-info" v-if="rule.necessaryPoints">{{ rule.necessaryPoints }} pontos
                </h6>
                <h6 class="mb-0 font-weight-bold text-gray-400" v-else>Não aplicável</h6>
              </div>
              <h6 class="mb-0 font-weight-bold text-success small mt-2" v-if="rule.necessaryPoints">(atual {{ points }})
              </h6>
              <h6 class="mb-0 font-weight-bold text-success small mt-2" v-if="rule.necessaryPoints && pointsToRetire">
                Completará
                em {{ pointsToRetire.format('DD/MM/YYYY') }} com {{ getNecessaryPoints(pointsToRetire.year(),
                  rule.gender,
                  rule.necessaryTeachingTime > 0) }} </h6>
            </div>
          </div>

          <div class="col-md-2">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Tempo Especial</h6>
              <div class="d-flex align-items-center">
                <h6 class="mb-0 font-weight-bold text-info" v-if="rule.necessarySpecialTime">
                  {{ rule.necessarySpecialTime }} anos
                </h6>
                <h6 class="mb-0 font-weight-bold text-gray-400" v-else>Não aplicável</h6>
              </div>
              <h6 class="mb-0 font-weight-bold text-success small mt-2" v-if="rule.necessarySpecialTime">
                ({{ specialTime }})</h6>
              <h6 class="mb-0 font-weight-bold text-success small mt-2"
                v-if="rule.necessarySpecialTime && specialTimeToRetire">
                Completará em {{ specialTimeToRetire.format('DD/MM/YYYY') }} </h6>
            </div>
          </div>

          <div class="col-md-2">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small" v-b-tooltip.hover
                v-bind:title="rule.benefitCalculusMethod">
                Cálculo do Benefício
                <b-icon class="h6 mb-0" icon="exclamation-circle-fill" variant="info"></b-icon>
              </h6>
              <div class="d-flex align-items-center">
                <span v-if="rule.estimatedBenefit" v-on:click="showBenefitReportModal = true">
                  R$ {{ formatMoney(rule.estimatedBenefit) }}
                </span>
                <router-link v-else class="text-primary" v-bind:to="{ name: 'work-records' }">
                  Incluir registro de trabalho 
                </router-link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <RuleBenefitReportModal v-if="showBenefitReportModal" v-bind:benefitLogs="rule.benefitLogs"
      v-on:hidden="handlerCloseModal" />
  </div>
</template>

<script>
import RuleBenefitReportModal from './RuleBenefitReportModal.vue';
import moment from 'moment';
import { getRegimeDate, getTime } from '../utils/common';

export default {
  components: { RuleBenefitReportModal },

  props: {
    rule: Object,
    isNotApplicable: Boolean,
    isFuture: Boolean,
  },

  data: () => ({
    showBenefitReportModal: false,
  }),

  computed: {
    lawDate: function () {
      return this.rule.ruleLimitDate ? moment(this.rule.ruleLimitDate) : moment();
    },

    currentAge: function () {
      return this.lawDate.diff(moment(this.rule?.customer?.birthDate), 'years');
    },

    increasedContribution: function () {
      const { increasedNecessaryContribution, increasedNecessaryContributionMonths, increasedNecessaryContributionDays, necessaryContribution } = this.rule;

      let text = "";

      let increasedYear = increasedNecessaryContribution > 0;
      let increasedMonth = increasedNecessaryContributionMonths > 0;
      let increasedDay = increasedNecessaryContributionDays > 0;

      if (increasedYear) {
        text += `+ ${Math.floor(increasedNecessaryContribution - necessaryContribution)} anos`;
      }

      if (increasedMonth) {
        text += (increasedYear) ? `${(increasedDay) ? "," : " e"} ${Math.floor(increasedNecessaryContributionMonths)} meses` : `+ ${Math.floor(increasedNecessaryContributionMonths)} meses`;
      }

      if (increasedDay) {
        text += (increasedYear || increasedMonth) ? ` e ${Math.floor(increasedNecessaryContributionDays)} dias` : `+ ${Math.floor(increasedNecessaryContributionDays)} dias`;
      }

      return text;
    },

    increasedTeachingTime: function () {
      const { increasedNecessaryTeachingTime, increasedNecessaryTeachingTimeMonths, increasedNecessaryTeachingTimeDays, necessaryTeachingTime } = this.rule;

      let text = "";

      let increasedYear = increasedNecessaryTeachingTime > 0;
      let increasedMonth = increasedNecessaryTeachingTimeMonths > 0;
      let increasedDay = increasedNecessaryTeachingTimeDays > 0;

      if (increasedYear) {
        text += `+ ${Math.floor(increasedNecessaryTeachingTime - necessaryTeachingTime)} anos`;
      }

      if (increasedMonth) {
        text += (increasedYear) ? `${(increasedDay) ? "," : " e"} ${Math.floor(increasedNecessaryTeachingTimeMonths)} meses` : `+ ${Math.floor(increasedNecessaryTeachingTimeMonths)} meses`;
      }

      if (increasedDay) {
        text += (increasedYear || increasedMonth) ? ` e ${Math.floor(increasedNecessaryTeachingTimeDays)} dias` : `+ ${Math.floor(increasedNecessaryTeachingTimeDays)} dias`;
      }

      return text;
    },

    formattedCurrentAge: function () {
      return this.rule.ruleLimitDate ? `${this.currentAge} em ${moment(this.rule.ruleLimitDate).format("DD/MM/YYYY")}` : `atual ${this.currentAge}`;
    },

    totalPoints: function () {
      return this.currentAge + this.rule?.calculation?.contributionTimeYears || 'indefinido';
    },

    contributionTime: function () {
      const workRecords = this.rule.workHistory;
      const regimeDate = this.rule?.regimeDate;

      if (this.rule.ruleLimitDate) {
        const { years, months, days } = getTime(workRecords, moment(this.rule.ruleLimitDate), null, this.rule.gender, regimeDate);

        return `${years || "0"} anos, ${months || "0"} meses e ${days || "0"} dias em ${moment(this.rule.ruleLimitDate).format("DD/MM/YYYY")}`;
      }

      let field = null;

      const isDisabledRule = this.rule.modality.includes("Deficiência");
      if (isDisabledRule) {
        let level = null;

        if (this.rule.modality.includes("Leve")) level = "low";
        if (this.rule.modality.includes("Moderada")) level = "medium";
        if (this.rule.modality.includes("Grave")) level = "high";

        field = "isDisabled";

        if (level) {
          field = `${field}-${level}`;
        }
      }

      const { years, months, days } = getTime(workRecords, null, field, this.rule.gender, regimeDate);

      return `atual ${years || "0"} anos, ${months || "0"} meses e ${days || "0"} dias`;
    },

    positionTime: function () {
      const workRecords = this.rule.workHistory;
      const regimeDate = this.rule?.regimeDate;

      if (this.rule.ruleLimitDate) {
        const { years, months, days } = getTime(workRecords, moment(this.rule.ruleLimitDate), "isCurrentPosition", this.rule.gender, regimeDate);

        return `${years || "0"} anos, ${months || "0"} meses e ${days || "0"} dias em ${moment(this.rule.ruleLimitDate).format("DD/MM/YYYY")}`;
      }

      const { years, months, days } = getTime(workRecords, null, "isCurrentPosition", this.rule.gender, regimeDate);

      return `atual ${years || "0"} anos, ${months || "0"} meses e ${days || "0"} dias`;
    },

    publicServiceTime: function () {
      const workRecords = this.rule.workHistory;
      const regimeDate = this.rule?.regimeDate;

      if (this.rule.ruleLimitDate) {
        const { years, months, days } = getTime(workRecords, moment(this.rule.ruleLimitDate), "isPublicService", this.rule.gender, regimeDate);

        return `${years || "0"} anos, ${months || "0"} meses e ${days || "0"} dias em ${moment(this.rule.ruleLimitDate).format("DD/MM/YYYY")}`;
      }

      const { years, months, days } = getTime(workRecords, null, "isPublicService", this.rule.gender, regimeDate);

      return `atual ${years || "0"} anos, ${months || "0"} meses e ${days || "0"} dias`;
    },

    carreerTime: function () {
      const workRecords = this.rule.workHistory;
      const regimeDate = this.rule?.regimeDate;

      if (this.rule.ruleLimitDate) {
        const { years, months, days } = getTime(workRecords, moment(this.rule.ruleLimitDate), "isCurrentCareer", this.rule.gender, regimeDate);

        return `${years || "0"} anos, ${months || "0"} meses e ${days || "0"} dias em ${moment(this.rule.ruleLimitDate).format("DD/MM/YYYY")}`;
      }

      const { years, months, days } = getTime(workRecords, null, "isCurrentCareer", this.rule.gender, regimeDate);

      return `atual ${years || "0"} anos, ${months || "0"} meses e ${days || "0"} dias`;
    },

    specialTime: function () {
      const workRecords = this.rule.workHistory;
      const regimeDate = this.rule?.regimeDate;

      if (this.rule.ruleLimitDate) {
        const { years, months, days } = getTime(workRecords, moment(this.rule.ruleLimitDate), "isSpecialTime", this.rule.gender, regimeDate);

        return `${years || "0"} anos, ${months || "0"} meses e ${days || "0"} dias em ${moment(this.rule.ruleLimitDate).format("DD/MM/YYYY")}`;
      }

      const { years, months, days } = getTime(workRecords, null, "isSpecialTime", this.rule.gender, regimeDate);

      return `atual ${years || "0"} anos, ${months || "0"} meses e ${days || "0"} dias`;
    },

    teachingTime: function () {
      const workRecords = this.rule.workHistory;
      const regimeDate = this.rule?.regimeDate;

      if (this.rule.ruleLimitDate) {
        const { years, months, days } = getTime(workRecords, moment(this.rule.ruleLimitDate), "isTeaching", this.rule.gender, regimeDate);

        return `${years || "0"} anos, ${months || "0"} meses e ${days || "0"} dias em ${moment(this.rule.ruleLimitDate).format("DD/MM/YYYY")}`;
      }

      const { years, months, days } = getTime(workRecords, null, "isTeaching", this.rule.gender, regimeDate);

      return `atual ${years || "0"} anos, ${months || "0"} meses e ${days || "0"} dias`;
    },

    points: function () {
      const isTeaching = this.rule?.necessaryTeachingTime > 0;

      const ageInDays = moment(this.rule?.calculationDate).diff(moment(this.rule?.customer.birthDate), 'days');

      let totalContribuiton = 0;

      if (isTeaching) {
        const { teachingTimeYears, teachingTimeMonths, teachingTimeDays } = this.rule?.calculation;

        totalContribuiton = (teachingTimeDays + (teachingTimeMonths * 30) + (teachingTimeYears * 365));
      } else {
        const { contributionTimeYears, contributionTimeMonths, contributionTimeDays } = this.rule?.calculation;

        totalContribuiton = (contributionTimeDays + (contributionTimeMonths * 30) + (contributionTimeYears * 365));
      }

      const totalPointsInDays = ageInDays + totalContribuiton;
      const totalPoints = Math.floor(totalPointsInDays / 365);

      return totalPoints;
    },

    ageToRetire: function () {
      return this.rule.ageToRetire ? moment(this.rule.ageToRetire) : false;
    },

    contributionToRetire: function () {
      return this.rule.contributionToRetire ? moment(this.rule.contributionToRetire) : false;
    },

    positionToRetire: function () {
      return this.rule.positionToRetire ? moment(this.rule.positionToRetire) : false;
    },

    publicServiceToRetire: function () {
      return this.rule.publicServiceToRetire ? moment(this.rule.publicServiceToRetire) : false;
    },

    teachingToRetire: function () {
      return this.rule.teachingToRetire ? moment(this.rule.teachingToRetire) : false;
    },

    carreerToRetire: function () {
      return this.rule.carreerToRetire ? moment(this.rule.carreerToRetire) : false;
    },

    specialTimeToRetire: function () {
      return this.rule.specialTimeToRetire ? moment(this.rule.specialTimeToRetire) : false;
    },

    hadBirthday: function () {
      const now = moment();

      const birthday = moment(this.rule?.customer?.birthDate);

      const currentMonth = now.month();
      const birthdayMonth = birthday.month();

      if (currentMonth > birthdayMonth) return true;

      if (birthdayMonth > currentMonth) return false;

      const currentDay = now.date();
      const birthdayDay = birthday.date();

      if (currentDay >= birthdayDay) return true;

      return false;
    },

    pointsToRetire: function () {
      return this.rule.pointsToRetire ? moment(this.rule.pointsToRetire) : false;
    },

    retiredDate: function () {
      if (!this.isFuture && !this.isNotApplicable) {
        let dates = [];

        const regimeDate = this.rule?.regimeDate;
        const limitDate = this.rule.ruleLimitDate ? moment(this.rule.ruleLimitDate) : moment();

        const necessaryAge = this.getDecreasedAge() || this.rule.necessaryAge || 0;
        const necessaryContributionTime = this.rule.necessaryContribution || 0;
        const necessaryPositionTime = this.rule.necessaryPositionTime || 0;
        const necessaryPublicServiceTime = this.rule.necessaryPublicServiceTime || 0;
        const necessaryTeachingTime = this.rule.necessaryTeachingTime || 0;
        const necessaryCarreerTime = this.rule.necessaryCarreerTime || 0;
        const necessarySpecialTime = this.rule.necessarySpecialTime || 0;
        const necessaryPoints = this.rule.necessaryPoints || 0;

        if (necessaryAge) {
          let years = 0;
          let months = 0;
          let days = 0;

          const start = moment(this.rule?.customer?.birthDate);

          let end = moment(limitDate);

          years = end.diff(start, 'years');
          end = end.subtract(years, 'years');
          months = end.diff(start, 'months');
          end = end.subtract(months, 'months');
          days = end.diff(start, 'days');

          const extraTime = years - necessaryAge;

          const date = moment(limitDate);

          let finalDate = date
            .subtract(extraTime || 0, 'years')
            .subtract(months, 'months')
            .subtract(days, 'days')

          if (this.rule?.ruleStartDate && moment(this.rule.ruleStartDate).isAfter(finalDate)) {
            finalDate = moment(this.rule.ruleStartDate).add(1, 'day');
          }

          dates.push(finalDate);
        }

        if (necessaryContributionTime) {
          if (this.rule?.modality.includes("Pedágio")) {
            const workRecords = this.rule?.workHistory || [];
            const {
              days: contributionTimeDaysIn2019,
              months: contributionTimeMonthsIn2019,
              years: contributionTimeYearsIn2019
            } = getTime(workRecords, moment(limitDate), null, this.rule.gender, regimeDate);

            let tollYears = 0;
            let tollMonths = 0;
            let tollDays = 0;

            if (contributionTimeYearsIn2019 < necessaryContributionTime) {
              tollYears = necessaryContributionTime - contributionTimeYearsIn2019;
              tollMonths = contributionTimeMonthsIn2019;
              tollDays = contributionTimeDaysIn2019;

              if (tollMonths > 0 || tollDays > 0) {
                tollYears -= 1;
              }

              if (tollMonths > 0) {
                tollMonths = 12 - tollMonths;
              }

              if (tollDays > 0) {
                tollMonths -= 1;
                tollDays = 30 - tollDays;
              }

              tollYears *= 2;
              tollMonths *= 2;
              tollDays *= 2;

              if (tollDays >= 30) {
                const daysToMonths = Math.floor(tollDays / 30);

                tollDays -= (daysToMonths * 30);
                tollMonths += daysToMonths;
              }

              if (tollMonths >= 12) {
                const monthsToYears = Math.floor(tollMonths / 12);

                tollMonths -= (monthsToYears * 12);
                tollYears += monthsToYears;
              }

              let finalDate = moment(limitDate)
                .add(tollYears, 'years')
                .add(tollMonths, 'months')
                .add(tollDays, 'days')

              if (this.rule?.ruleStartDate && moment(this.rule.ruleStartDate).isAfter(finalDate)) {
                finalDate = moment(this.rule.ruleStartDate).add(1, 'day');
              }

              dates.push(finalDate);
            } else {
              const extraYears = contributionTimeYearsIn2019 - necessaryContributionTime;

              let finalDate = moment(limitDate)
                .subtract(extraYears || 0, 'years')
                .subtract(contributionTimeMonthsIn2019 || 0, 'months')
                .subtract(contributionTimeDaysIn2019 || 0, 'days')

              if (this.rule?.ruleStartDate && moment(this.rule.ruleStartDate).isAfter(finalDate)) {
                finalDate = moment(this.rule.ruleStartDate).add(1, 'day');
              }

              dates.push(finalDate);
            }
          } else {
            const workRecords = this.rule?.workHistory || [];

            const {
              days: contributionTimeDays,
              months: contributionTimeMonths,
              years: contributionTimeYears
            } = getTime(workRecords, moment(limitDate), null, this.rule?.gender, regimeDate);

            const extraTime = contributionTimeYears - necessaryContributionTime;

            let finalDate = moment(limitDate)
              .subtract(extraTime || 0, 'years')
              .subtract(contributionTimeMonths || 0, 'months')
              .subtract(contributionTimeDays || 0, 'days')

            if (this.rule?.ruleStartDate && moment(this.rule.ruleStartDate).isAfter(finalDate)) {
              finalDate = moment(this.rule.ruleStartDate).add(1, 'day');
            }

            dates.push(finalDate);
          }
        }

        if (necessaryPositionTime) {
          const extraTime = this.rule?.calculation?.positionTimeYears - necessaryPositionTime;

          let finalDate = moment(limitDate).subtract(extraTime || 0, 'years')
            .subtract(this.rule?.calculation?.positionTimeMonths || 0, 'months')
            .subtract(this.rule?.calculation?.positionTimeDays || 0, 'days')

          if (this.rule?.ruleStartDate && moment(this.rule.ruleStartDate).isAfter(finalDate)) {
            finalDate = moment(this.rule.ruleStartDate).add(1, 'day');
          }

          dates.push(finalDate);
        }

        if (necessaryPublicServiceTime) {
          const extraTime = this.rule?.calculation?.publicServiceTimeYears - necessaryPublicServiceTime;

          let finalDate = moment(limitDate).subtract(extraTime || 0, 'years')
            .subtract(this.rule?.calculation?.publicServiceTimeMonths || 0, 'months')
            .subtract(this.rule?.calculation?.publicServiceTimeDays || 0, 'days')

          if (this.rule?.ruleStartDate && moment(this.rule.ruleStartDate).isAfter(finalDate)) {
            finalDate = moment(this.rule.ruleStartDate).add(1, 'day');
          }

          dates.push(finalDate);
        }

        if (necessaryTeachingTime) {
          if (this.rule?.modality.includes("Pedágio")) {
            const workRecords = this.rule?.workHistory || [];
            const {
              days: teachingTimeDaysIn2019,
              months: teachingTimeMonthsIn2019,
              years: teachingTimeYearsIn2019
            } = getTime(workRecords, moment(limitDate), 'isTeaching', this.rule?.gender, regimeDate);

            let tollYears = 0;
            let tollMonths = 0;
            let tollDays = 0;

            if (teachingTimeYearsIn2019 < necessaryTeachingTime) {
              tollYears = necessaryTeachingTime - teachingTimeYearsIn2019;
              tollMonths = teachingTimeMonthsIn2019;
              tollDays = teachingTimeDaysIn2019;

              if (tollMonths > 0 || tollDays > 0) {
                tollYears -= 1;
              }

              if (tollMonths > 0) {
                tollMonths = 12 - tollMonths;
              }

              if (tollDays > 0) {
                tollMonths -= 1;
                tollDays = 30 - tollDays;
              }

              tollYears *= 2;
              tollMonths *= 2;
              tollDays *= 2;

              if (tollDays >= 30) {
                const daysToMonths = Math.floor(tollDays / 30);

                tollDays -= (daysToMonths * 30);
                tollMonths += daysToMonths;
              }

              if (tollMonths >= 12) {
                const monthsToYears = Math.floor(tollMonths / 12);

                tollMonths -= (monthsToYears * 12);
                tollYears += monthsToYears;
              }

              let finalDate = moment(limitDate)
                .add(tollYears, 'years')
                .add(tollMonths, 'months')
                .add(tollDays, 'days')

              if (this.rule?.ruleStartDate && moment(this.rule.ruleStartDate).isAfter(finalDate)) {
                finalDate = moment(this.rule.ruleStartDate).add(1, 'day');
              }

              dates.push(finalDate);
            } else {
              const extraYears = teachingTimeYearsIn2019 - necessaryTeachingTime;

              let finalDate = moment(limitDate)
                .subtract(extraYears || 0, 'years')
                .subtract(teachingTimeMonthsIn2019 || 0, 'months')
                .subtract(teachingTimeDaysIn2019 || 0, 'days')

              if (this.rule?.ruleStartDate && moment(this.rule.ruleStartDate).isAfter(finalDate)) {
                finalDate = moment(this.rule.ruleStartDate).add(1, 'day');
              }

              dates.push(finalDate);
            }
          } else {
            const extraTime = this.rule?.calculation?.teachingTimeYears - necessaryTeachingTime;

            let finalDate = moment(limitDate)
              .subtract(extraTime || 0, 'years')
              .subtract(this.rule?.calculation?.teachingTimeMonths || 0, 'months')
              .subtract(this.rule?.calculation?.teachingTimeDays || 0, 'days')

            if (this.rule?.ruleStartDate && moment(this.rule.ruleStartDate).isAfter(finalDate)) {
              finalDate = moment(this.rule.ruleStartDate).add(1, 'day');
            }

            dates.push(finalDate);
          }
        }

        if (necessaryCarreerTime) {
          const extraTime = this.rule?.calculation?.carreerTimeYears - necessaryCarreerTime;

          let finalDate = moment(limitDate).subtract(extraTime || 0, 'years')
            .subtract(this.rule?.calculation?.carreerTimeMonths || 0, 'months')
            .subtract(this.rule?.calculation?.carreerTimeDays || 0, 'days')

          if (this.rule?.ruleStartDate && moment(this.rule.ruleStartDate).isAfter(finalDate)) {
            finalDate = moment(this.rule.ruleStartDate).add(1, 'day');
          }

          dates.push(finalDate);
        }

        if (necessarySpecialTime) {
          const workRecords = this.rule?.workHistory || [];
          const {
            days: specialTimeDays,
            months: specialTimeMonths,
            years: specialTimeYears
          } = getTime(workRecords, this.rule?.ruleLimitDate, 'isSpecialTime', this.rule?.gender, regimeDate);

          const extraTime = specialTimeYears - necessarySpecialTime;

          const date = this.rule?.ruleLimitDate ? moment(this.rule?.ruleLimitDate) : moment();

          let finalDate = date.subtract(extraTime || 0, 'years')
            .subtract(specialTimeMonths || 0, 'months')
            .subtract(specialTimeDays || 0, 'days')

          if (this.rule?.ruleStartDate && moment(this.rule.ruleStartDate).isAfter(finalDate)) {
            finalDate = moment(this.rule.ruleStartDate).add(1, 'day');
          }

          dates.push(finalDate);
        }

        dates = dates.filter(d => d);

        if (dates.length === 0) return false;

        let lastDate = moment.max(dates);

        if (necessaryPoints) {
          const isTeaching = necessaryTeachingTime > 0;
          const isFixedPoints = this.rule?.fixedPoints;
          const workRecords = this.rule?.workHistory || [];
          const gender = this.rule?.gender;

          let currentContributionYears = 0;
          let currentContributionMonths = 0;
          let currentContributionDays = 0;

          if (isTeaching) {
            const {
              days: teachingTimeDays,
              months: teachingTimeMonths,
              years: teachingTimeYears
            } = getTime(workRecords, moment(lastDate), 'isTeaching', this.rule?.gender, regimeDate);

            currentContributionYears = teachingTimeYears;
            currentContributionMonths = teachingTimeMonths;
            currentContributionDays = teachingTimeDays;
          } else {
            const {
              days: contributionTimeDays,
              months: contributionTimeMonths,
              years: contributionTimeYears
            } = getTime(workRecords, moment(lastDate), null, this.rule.gender, regimeDate);

            currentContributionYears = contributionTimeYears;
            currentContributionMonths = contributionTimeMonths;
            currentContributionDays = contributionTimeDays;
          }

          currentContributionDays += currentContributionMonths * 30;
          currentContributionDays += currentContributionYears * 365;

          const ageInDays = moment(lastDate).diff(moment(this.rule.customer?.birthDate), 'days');

          const pointsInDays = currentContributionDays + ageInDays;

          let now = moment(lastDate);
          let pastDate = moment(lastDate);
          let newYear = now.year();

          let currentNecessaryPoints = isFixedPoints ? necessaryPoints : this.getNecessaryPoints(newYear, gender, isTeaching);
          let currentNecessaryPointsInDays = currentNecessaryPoints * 365;

          if (currentNecessaryPointsInDays > pointsInDays) {
            const logs = [];

            do {
              const currentYear = newYear;

              currentNecessaryPoints = isFixedPoints ? necessaryPoints : this.getNecessaryPoints(currentYear, gender, isTeaching);
              currentNecessaryPointsInDays = currentNecessaryPoints * 365;

              const missingDays = Math.floor((currentNecessaryPointsInDays - pointsInDays) / 2);
              // const missingDays = (currentNecessaryPointsInDays - pointsInDays) / 2;

              now.add(missingDays, 'days');

              newYear = now.year();

              logs.push({ currentYear, newYear, currentNecessaryPoints, currentNecessaryPointsInDays, pointsInDays, missingDays });

              if (newYear !== currentYear) {
                now = moment(lastDate);
                continue;
              }

              pastDate = now;
              break;
            } while (true);

            // console.log(pastDate);
            // console.table(logs);

            dates.push(pastDate);
            lastDate = pastDate;
          }
        }

        // console.log({
        //   rule: this.rule.modality,
        //   sequence: [
        //     !necessaryAge || "age", 
        //     !necessaryContributionTime || "contribution", 
        //     !necessaryPositionTime || "position time",
        //     !necessaryPublicServiceTime || "public service", 
        //     !necessaryTeachingTime || "teaching", 
        //     !necessaryCarreerTime || "carreer", 
        //     !necessarySpecialTime || "special", 
        //     !necessaryPoints ||"points"
        //   ],
        //   dates: dates.map(d => d._d)
        // });

        return lastDate.format('DD/MM/YYYY');
      }
      return false
    },

    whenToRetire: function () {
      return this.rule.whenToRetire ? moment(this.rule.whenToRetire) : false;

      // const isNewRule = (this.rule.ruleStartDate);
      // const checkNewRule = (this.rule.calculation.regime !== "Sem Reforma");

      // const newRuleCondition = ((!checkNewRule) || (checkNewRule && isNewRule));

      // if (this.isFuture && newRuleCondition) {
      //   let dates = [];

      //   dates.push(this.ageToRetire);
      //   dates.push(this.contributionToRetire);
      //   dates.push(this.positionToRetire);
      //   dates.push(this.publicServiceToRetire);
      //   dates.push(this.teachingToRetire);
      //   dates.push(this.carreerToRetire);
      //   dates.push(this.pointsToRetire);

      //   dates = dates.filter(d => d);

      //   if (dates.length === 0) return false;

      //   let lastDate = moment.max(dates); 

      //   return lastDate;
      // }
      // return false
    },
  },

  methods: {
    handlerCloseModal() {
      this.showBenefitReportModal = false;
    },

    formatMoney(value, digits) {
      const d = digits !== undefined ? digits : 2;
      const nf = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: d,
        maximumFractionDigits: d,
      });
      return nf.format(value);
    },

    // getTime(workRecords, limitDate = null, field = null, gender, regimeDate) {
    //   const dates = [];

    //   let disablementLevel = null;

    //   if (field && field.includes("isDisabled")) {
    //     disablementLevel = field.split("-")[1];
    //     field = "isDisabled";
    //   }

    //   workRecords.forEach((workRecord) => {
    //     const { ingressDate, egressDate, addition, deduction1, deduction2, deduction3, isSpecialTime, disabledLevel } = workRecord;      

    //     if (!field || (field && workRecord[field] && (!disablementLevel || disablementLevel === disabledLevel))) {
    //       if (isSpecialTime && !field && moment(ingressDate).isBefore(moment(regimeDate))) {
    //         const multiple = (gender === "Homem") ? 1.4 : 1.2;

    //         if (limitDate) {
    //           if (moment(ingressDate).isBefore(limitDate)) {
    //             let egress = null;

    //             let isLimitDate = false;

    //             if (moment(egressDate).isBefore(limitDate)) {
    //               egress = moment(egressDate);
    //             } else {
    //               egress = moment(limitDate);
    //               isLimitDate = true;
    //             }

    //             let isBefore2019 = false;

    //             if (egress.isSameOrBefore(regimeDate)) {
    //               egress = moment(isLimitDate ? limitDate : egressDate);
    //               isBefore2019 = true;
    //             } else {
    //               egress = moment(regimeDate);
    //             }

    //             let ingress = moment(ingressDate);

    //             egress.add((addition * 2), 'days');
    //             egress.subtract(deduction1, 'days');

    //             if (moment(limitDate).isAfter(moment('1998-12-16'))) {
    //               egress.subtract(deduction2, 'days');
    //             }

    //             if (moment(limitDate).isAfter(moment(regimeDate))) {
    //               egress.subtract(deduction3, 'days');
    //             }

    //             let years = egress.diff(ingress, 'years');
    //             egress = egress.subtract(years, 'years');
    //             let months = egress.diff(ingress, 'months');
    //             egress = egress.subtract(months, 'months');
    //             let days = egress.diff(ingress, 'days');

    //             years *= multiple;
    //             months *= multiple;
    //             days *= multiple;

    //             if (!isBefore2019) {
    //               egress = moment(isLimitDate ? limitDate : egressDate);
    //               ingress = moment(regimeDate).add(1, 'day');

    //               let years2 = egress.diff(ingress, 'years');
    //               egress = egress.subtract(years2, 'years');
    //               let months2 = egress.diff(ingress, 'months');
    //               egress = egress.subtract(months2, 'months');
    //               let days2 = egress.diff(ingress, 'days');

    //               years += years2;
    //               months += months2;
    //               days += days2;
    //             }

    //             dates.push({ years, months, days });
    //           }
    //         } else {
    //           let egress = null;
    //           let isBefore2019 = false;

    //           if (moment(egressDate).isSameOrBefore(regimeDate)) {
    //             egress = moment(egressDate);
    //             isBefore2019 = true;
    //           } else {
    //             egress = moment(regimeDate);
    //           }

    //           let ingress = moment(ingressDate);

    //           egress.add((addition * 2), 'days');
    //           egress.subtract(deduction1, 'days');
    //           egress.subtract(deduction2, 'days');
    //           egress.subtract(deduction3, 'days');

    //           let years = egress.diff(ingress, 'years');
    //           egress = egress.subtract(years, 'years');
    //           let months = egress.diff(ingress, 'months');
    //           egress = egress.subtract(months, 'months');
    //           let days = egress.diff(ingress, 'days');

    //           years *= multiple;
    //           months *= multiple;
    //           days *= multiple;

    //           if (!isBefore2019) {
    //             egress = moment(egressDate);
    //             ingress = moment(regimeDate).add(1, 'day');

    //             let years2 = egress.diff(ingress, 'years');
    //             egress = egress.subtract(years2, 'years');
    //             let months2 = egress.diff(ingress, 'months');
    //             egress = egress.subtract(months2, 'months');
    //             let days2 = egress.diff(ingress, 'days');

    //             years += years2;
    //             months += months2;
    //             days += days2;
    //           }

    //           dates.push({ years, months, days });
    //         }
    //       } else {
    //         if (limitDate) {

    //           if (moment(ingressDate).isBefore(limitDate)) {
    //             let egress = null;

    //             if (moment(egressDate).isBefore(limitDate)) {
    //               egress = moment(egressDate)
    //             } else {
    //               egress = moment(limitDate);
    //             }

    //             let ingress = moment(ingressDate);

    //             egress.add((addition * 2), 'days');
    //             egress.subtract(deduction1, 'days');

    //             if (moment(limitDate).isAfter(moment('1998-12-16'))) {
    //               egress.subtract(deduction2, 'days');
    //             }

    //             if (moment(limitDate).isAfter(moment(regimeDate))) {
    //               egress.subtract(deduction3, 'days');
    //             }

    //             const years = egress.diff(ingress, 'years');
    //             egress = egress.subtract(years, 'years');
    //             const months = egress.diff(ingress, 'months');
    //             egress = egress.subtract(months, 'months');
    //             const days = egress.diff(ingress, 'days');

    //             // if (teste) console.log({ years, months, days });

    //             dates.push({ years, months, days });
    //           }
    //         } else {
    //           let egress = moment(egressDate);
    //           let ingress = moment(ingressDate);

    //           egress.add((addition * 2), 'days');
    //           egress.subtract(deduction1, 'days');
    //           egress.subtract(deduction2, 'days');
    //           egress.subtract(deduction3, 'days');

    //           const years = egress.diff(ingress, 'years');
    //           egress = egress.subtract(years, 'years');
    //           const months = egress.diff(ingress, 'months');
    //           egress = egress.subtract(months, 'months');
    //           const days = egress.diff(ingress, 'days');

    //           dates.push({ years, months, days });
    //         }
    //       }
    //     }
    //   });

    //   let years = 0;
    //   let months = 0;
    //   let days = 0;

    //   for (const date of dates) {
    //     years += date.years;
    //     months += date.months;
    //     days += date.days;
    //   }

    //   if (days >= 30) {
    //     const daysToMonths = Math.floor(days / 30);

    //     days -= (daysToMonths * 30);
    //     months += daysToMonths;
    //   }

    //   if (months >= 12) {
    //     const monthsToYears = Math.floor(months / 12);

    //     months -= (monthsToYears * 12);
    //     years += monthsToYears;
    //   }

    //   years = Math.floor(years);
    //   months = Math.floor(months);
    //   days = Math.floor(days);

    //   return { years, months, days };
    // },

    getNecessaryPoints(year, gender, isTeaching = false) {
      const difference = year - 2019;

      let points = 0;

      switch (gender) {
        case "Homem":
          if (year >= 2028) {
            points = 105;
            break;
          }

          if (year < 2019) {
            points = 95;
            break;
          }

          const malePointsIn2019 = 96;
          points = malePointsIn2019 + difference;

          break;
        case "Mulher":
          if (year >= 2033) {
            points = 100;
            break;
          }

          if (year < 2019) {
            points = 85;
            break;
          }

          const femalePointsIn2019 = 86;
          points = femalePointsIn2019 + difference;

          break;
      }

      if (isTeaching) {
        points -= 5;
      }

      if (isTeaching && gender === "Mulher" && points > 92) points = 92;

      return points;
    },

    getDecreasedAge() {
      const { decreasedNecessaryAge, necessaryAge } = this.rule;

      if (!decreasedNecessaryAge) return null;

      let descreasedAge = 0;
      let age = 0;

      const maxContribution = (this.rule?.gender === "Homem") ? 35 : 30;

      const limitYear = moment(this.rule?.ruleLimitDate);

      const regimeDate = getRegimeDate(this.rule?.regimeDate);

      do {
        let { years: contributionTimeYears } = getTime(this.rule?.workHistory, limitYear, null, this.rule?.gender, regimeDate);

        descreasedAge = necessaryAge - (contributionTimeYears - maxContribution);
        age = limitYear.diff(moment(this.rule?.customer?.birthDate), 'years');

        limitYear.subtract(1, 'year');
      } while (age > descreasedAge);

      return descreasedAge;
    },

    // getRegimeDate(regime) {
    //   const dates = {
    //     'União': '2019-11-13',
    //     'PR': '2019-12-03',
    //     'SP': '2020-03-07',
    //     'ES': '2020-07-01',
    //     'RS': '2019-12-22',
    //     'MT': '2020-08-20',
    //     'PB': '2021-07-02',
    //     'Curitiba': '2021-12-31'
    //   };

    //   let date = dates[regime];

    //   if (regime === 'Sem Reforma') date = moment().add(1, 'day').format('YYYY-MM-DD');

    //   return moment(date);
    // }
  },
}
</script>

<style lang="scss">
.no-break {
  break-inside: avoid;
}

.rule-not-applicable.bg-white-blue {
  background-color: #f7eced !important;
  border-color: #df7680 !important;
}

.rule-not-applicable {

  .border-bottom,
  .border-right {
    border-color: transparent !important;
  }

  .text-gray-400 {
    color: #ce9ca0 !important;
  }

  .text-primary,
  .text-success,
  .text-info {
    color: #c44a56 !important;
  }
}

.future-rule.bg-white-blue {
  background-color: #f5f0e7 !important;
  border-color: #d0ba90 !important;
}

.future-rule {

  .border-bottom,
  .border-right {
    border-color: transparent !important;
  }

  .text-gray-400 {
    color: #d7c4a1 !important;
  }

  .text-primary,
  .text-success,
  .text-info {
    color: #a8884b !important;
  }
}
</style>
