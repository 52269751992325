<template>
  <div class="users">
    <main role="main" class="container">
      <div class="my-3 p-3 bg-white rounded shadow-sm">
        <h3 class="border-bottom border-gray pb-2 mb-0">
          <!-- Usuários -->
        </h3>
        <router-link :to="{ name: 'edit-user', params: { id: 'new'} }">
          <button class="btn btn-primary ml-3 float-right">
            <i class="fa fa-user-plus mr-2"></i>  Novo Usuário
          </button>
        </router-link>
        <button class="btn btn-primary ml-3 float-right" @click="exportExcel">
          <i class="fa fa-file-excel mr-2"></i>  Exportar
        </button>
        <table class="table">
          <thead>
            <tr>
              <!-- <th scope="col">#</th> -->
              <th scope="col">Nome</th>
              <th scope="col">Email</th>
              <th scope="col">Status</th>
              <th scope="col">Última Ação</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in allUsers" v-bind:key="user.id">
              <!-- <th scope="row">{{user.id}}</th> -->
              <!-- <th><UserAvatar /></th> -->
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.active ? 'Ativo' : 'Inativo' }}</td>
              <td>{{ user.lastActionAt ? formatDate(user.lastActionAt) : '---' }}</td>
              <td>
                <!-- <button class="btn btn-sm btn-warning ml-2" @click="deleteUser(user.id)"> -->
                <router-link :to="{ name: 'edit-user', params: { id: user.id } }">
                  <button class="btn btn-sm btn-warning ml-2">
                    <i class="fa fa-pencil"></i>
                  </button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from 'moment';

export default {
  name: 'Users',
  computed: mapGetters(['allUsers']),
  methods: {
    ...mapActions(['fetchUsers', 'deleteUser']),

    formatDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },

    exportExcel() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Usuários",
        Subject: "Usuários",
        Author: "GenialPrev",
        CreatedDate: new Date(),
      };

      wb.SheetNames.push("Report Sheet");

      let ws_data = [
        [
          "Nome",
          "Email",
          "Status",
          "Última Ação",
        ],
      ];

      this.allUsers.forEach((user) => {
        const row = [
          user.name,
          user.email,
          user.active ? 'Ativo' : 'Inativo',
          user.lastActionAt ? this.formatDate(user.lastActionAt) : '---'
        ];

        ws_data.push(row);
      });

      let ws = XLSX.utils.aoa_to_sheet(ws_data);

      wb.Sheets["Report Sheet"] = ws;

      let wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      let buf = new ArrayBuffer(wbout.length); //convert s to arrayBuffer
      let view = new Uint8Array(buf); //create uint8array as viewer

      for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xff; //convert to octet

      saveAs(
        new Blob([buf], { type: "application/octet-stream" }),
        "Usuários.xlsx"
      );
    },
  },
  components: {},
  created() {
    this.fetchUsers();
  }
}
</script>

<style scoped>
.btn-actionss {}
</style>
